/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import {AvailableOfficerRoles, AvailableOfficerRoleDescriptions} from '../components/availableOfficerRoles';
function _createMdxContent(props) {
  const _components = Object.assign({
    h3: "h3",
    p: "p",
    strong: "strong"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h3, null, "Virkailijahaku auki vuodelle"), "\n", React.createElement(_components.p, null, "Haluaisitko olla vaikuttamassa Hukan toimintaan? Olisiko sinulla intoa tehdä jotakin Hukan hyväksi?\r\nHukka on yhdistys ja virkailijaksi/hallitukseen tuleminen on yksi helppo tapa auttaa meitä. Aiempaa\r\nkokemusta yhdistystyöstä et tarvitse. Toiminta on meillä varsin rentoa ja voit osallistua sen\r\nverran, mikä tuntuu hyvältä."), "\n", React.createElement(_components.p, null, "Haettavana olisi seuraavat virat:"), "\n", React.createElement(AvailableOfficerRoles), "\n", React.createElement(_components.p, null, "Voit tulla myös hallitukseen, vaikka sinulla ei olisikaan kiinnostusta mihinkään tiettyyn virkaan.\r\nErityisesti uudehkot harrastajat olisivat erittäin toivottuja tuoreen näkökulman vuoksi!"), "\n", React.createElement(_components.p, null, "Lisäksi on haussa myös yksi tai kaksi toiminnantarkastajaa sekä varahenkilöt näille.\r\nToiminnantarkastus on tärkeä mutta aika helppo virka. Kevään 2025 aikana toiminnantarkastaja\r\ntarkastaa yhdistyksen tilit sekä toiminnan edeltävältä vuodelta (2023) ja antaa lausunnon niistä\r\nkevätkokoukselle. Pääsääntöisesti Hukan toiminta on sen verran pienimuotoista, että siinä ei ole\r\nihan hirveästi syynättävää."), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Laita vapaamuotoinen hakemus voudille"), " (vouti at hucca.org) viimeistään lauantaina 2.12.2023."), "\n", React.createElement(_components.p, null, "Tässä virkojen kuvaukset"), "\n", React.createElement(AvailableOfficerRoleDescriptions));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
