/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    p: "p"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, null, "Kutsu kevätkokoukseen"), "\n", React.createElement(_components.p, null, "Tervetuloa yhdistyksen kevätkokoukseen! Kokous pidetään Helsingin Maunulassa osoitteessa Maunulanmäki 1\r\nsunnuntaina 9.6. klo 14.00. Esityslistalla ovat sääntömääräiset kevätkokousasiat, eli vuoden 2023 vuosikertomus,\r\ntilinpäätös, toiminnantarkastajan lausunnon läpikäyminen sekä vastuuvapauden myöntäminen vuoden 2023 hallitukselle."), "\n", React.createElement(_components.p, null, "Kaikki ovat tervetulleita kokoukseen! Jokaisella paikalle tulevalla on läsnäolo- ja puheoikeus. äänioikeutettuja ovat\r\nKeskiaikaseura Hukka ry:n jäsenet."), "\n", React.createElement(_components.p, null, "Kokouksen yhteydessä pidämme pienimuotoiset nyyttärit sekä vapaamuotoista käsitöiden tekoa klo 13 alkaen. Myös uudet ovat erittäin\r\ntervetulleita. Jos olet tulossa, kerrothan voudille asiasta, niin saat tarkan osoitteen sekä ovikoodin."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
