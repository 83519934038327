/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h3: "h3",
    p: "p",
    ul: "ul",
    li: "li"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h3, null, "Kutsu syyskokoukseen"), "\n", React.createElement(_components.p, null, "Taas on se aika vuodesta, kun pitäisi päättää ensi vuodelle hallitus ja virkailijat sekä päättää, mitä\r\nHukka tekee ensi vuonna. Tule yhdistyksen syyskokoukseen sunnuntaina 3.12.2023 klo 18.30. Illalla on\r\nskriptorium, joten voit samalla kokoukseen osallistuessa maalailla tai kirjoitella."), "\n", React.createElement(_components.p, null, "Kokouksessa käsiteltävät asiat:"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Toimintasuunnitelma, tulo- ja menoarvio sekä liittymis- ja jäsenmaksun suuruuden vahvistaminen"), "\n", React.createElement(_components.li, null, "Hallituksen puheenjohtajan ja muiden jäsenten sekä virkailijoiden valinta"), "\n", React.createElement(_components.li, null, "Yhden tai kahden toiminnantarkastajan ja heidän varahenkilöiden valinta"), "\n"), "\n", React.createElement(_components.p, null, "Kokouksessa äänivaltaisia ovat kaikki Keskiaikaseura Hukka ry:n jäsenet, mutta läsnäolo- ja puheoikeus\r\non kaikilla asiasta kiinnostuneilla, eli rohkeasti mukaan kaikki!"), "\n", React.createElement(_components.p, null, "Kokous pidetään Nuotalla, eli osoite on tuttu Kylänvanhimmantie 25 B, 00640 Helsinki"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
