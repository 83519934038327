/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import {ExternalLink} from '../components/external-link';
function _createMdxContent(props) {
  const _components = Object.assign({
    h3: "h3",
    p: "p"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h3, null, "Kekrin ilmoittautuminen alkaa maanantaina 16.9."), "\n", React.createElement(_components.p, null, "Hukan oman Kekrijuhla-tapahtuman ilmoittautuminen alkaa 16.9. klo 18.00. Tule mukaan! Lisätietoa ja linkin ilmoittautumiseen\r\nlöydät ", React.createElement(ExternalLink, {
    href: "https://kekrijuhla.wordpress.com/"
  }, "tapahtuman nettisivuilta"), "."), "\n", React.createElement(_components.h3, null, "2024 syyskauden toiminta"), "\n", React.createElement(_components.p, null, "Syyskausi on hyvässä vauhdissa. Tapaamisisista lisätietoa löydät Kalenteri-sivulta. Uusia ihmisiä on ollut\r\njo paljon mukana syksyn aikana, mutta mukaan voi tulla milloin vain!"), "\n", React.createElement(_components.p, null, "Taistelutreenit siirtyvät sisätiloihin Viikkiin torstaina 19.9."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
